<script setup lang="ts">
import { subject } from '@casl/ability';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const {
  adminOnlyUploads,
  piiDetection,
  promptInjectionDetection,
  retentionLength,
  subscriptionType,
  updateWorkspace,
  workspace,
} = storeToRefs(workspaceStore);

const updatedAdminOnlyUploads = ref(adminOnlyUploads.value);
const updatedPiiDetection = ref(piiDetection.value);
const updatedPromptInjectionDetection = ref(promptInjectionDetection.value);

const retentionLengthOptions = [
  {
    id: null,
    label: 'Forever',
  },
  {
    id: 'oneWeek',
    label: 'One week',
  },
  {
    id: 'twoWeeks',
    label: 'Two weeks',
  },
  {
    id: 'threeWeeks',
    label: 'Three weeks',
  },
  {
    id: 'oneMonth',
    label: 'One month',
  },
];

const updatedRetentionLength = ref(
  retentionLengthOptions.find((option) => option.id === retentionLength.value),
);

// TODO: These are not implemented yet and are hard coded.
// const updatedMembersCanJoin = ref(true);

const settings = ref([
  {
    id: 'adminOnlyUploads',
    enabled: true,
    title: 'Restrict uploading files to admins',
    model: updatedAdminOnlyUploads,
    description:
      'When enabled, only admins will be able to upload files to the data sources feature.',
  },
  {
    id: 'piiDetection',
    enabled: true,
    title: 'Require all spells to use PII Detection',
    model: updatedPiiDetection,
    description:
      'When enabled, every spell in this workspace will use PII Detection. If PII is passed as an input to the spell, the spell run will fail.',
  },
  {
    id: 'promptInjectionDetection',
    enabled: true,
    title: 'Require all spells to use Prompt Injection Detection',
    model: updatedPromptInjectionDetection,
    description:
      'When enabled, every spell in this workspace will use Prompt Injection Detection. If we detect an attempt to bypass your compliance controls or AI system instructions, the spell run will fail.',
  },
  // {
  //   id: 'membersCanJoin',
  //   enabled: false,
  //   title: 'Allow members to join other workspaces',
  //   model: updatedMembersCanJoin,
  //   description:
  //     'When enabled, this will allow users in this workspace to join other workspaces.',
  // },
]);

const updatedRetentionLengthValue = computed(() => {
  if (updatedRetentionLength.value === null) {
    return null;
  }
  return typeof updatedRetentionLength.value === 'object'
    ? updatedRetentionLength.value.id
    : updatedRetentionLength.value;
});

const options = computed(() => {
  return {
    adminOnlyUploads: updatedAdminOnlyUploads,
    piiDetection: updatedPiiDetection,
    promptInjectionDetection: updatedPromptInjectionDetection,
    retentionLength: updatedRetentionLengthValue,
    // membersCanJoin: updatedMembersCanJoin,
  };
});

useApi(`/api/workspaces/${workspace.value.id}`, {
  method: 'PATCH',
  body: options.value,
  immediate: false,
  onResponse({ response }) {
    workspace.value = response._data;
  },
});

const canEdit = computed(() => {
  return (
    can('edit', subject('Workspace', workspace.value)) &&
    subscriptionType.value === 'enterprise'
  );
});
</script>
<template>
  <NuxtLayout name="account" :containerless="true">
    <p class="main-title mb-8">Workspace Security Settings</p>
    <hr />
    <div class="py-4">
      <div
        v-for="(setting, index) of settings"
        :key="setting.id"
        class="py-4 flex justify-start"
      >
        <UToggle
          size="xl"
          v-model="settings[index].model"
          :disabled="!(canEdit && setting.enabled)"
        />
        <div class="ml-4">
          <p
            class="text-lg subtitle"
            :class="!(canEdit && setting.enabled) && 'dimmed'"
          >
            {{ setting.title }}
          </p>
          <p class="text-lg dimmed">
            {{ setting.description }}
          </p>
        </div>
      </div>
      <div class="py-4">
        <p class="text-lg subtitle" :class="!canEdit && 'dimmed'">
          Retention Policy
        </p>
        <p class="text-lg dimmed">
          Spell run histories and data can be automatically be deleted after set
          periods.
        </p>
        <USelectMenu
          placeholder="Forever"
          class="py-4 max-w-xs"
          v-model="updatedRetentionLength"
          :options="retentionLengthOptions"
        />
      </div>
    </div>
  </NuxtLayout>
</template>
